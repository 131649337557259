.fotorama__arr {
    &--prev {
        display: block;
        opacity: 1;
        transform: translate3d(0, 0, 0);

        .fotorama__arr__arr {
            left: 0;
            background-image: url("data:image/svg+xml,%0A%3Csvg width='16' height='31' viewBox='0 0 16 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 30L1 15.5L15 1' stroke='%23E3E4E8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            background-position: 0;
        }
    }

    &--next {
        right: 0;
        display: block;
        opacity: 1;
        transform: translate3d(0, 0, 0);

        @media only screen and (max-width: 1280px) {
            right: -22px;
        }

        .fotorama__arr__arr {
            right: 0;
            background-image: url("data:image/svg+xml,%0A%3Csvg width='16' height='31' viewBox='0 0 16 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L15 15.5L1 30' stroke='%23E3E4E8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            background-position: 0;
        }
    }
}
