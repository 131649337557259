@mixin block-title() {
    font-family: $font-family__brand;
    text-transform: uppercase;
    font-weight: $font-weight__regular;
    margin-bottom: $indent__base;
    color: $brand__secondary__color;

    > strong {
        font-weight: $font-weight__bold;
    }
}
