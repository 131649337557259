.pagebuilder-video-inner {
    height: 550px;
    margin-bottom: 20px;
    position: relative;

    @media only screen and (max-width: 768px) {
        height: 200px;
    }

    .pagebuilder-video-wrapper {
        bottom: 0;
        left: 50%;
        margin-left: -50vw;
        overflow: hidden;
        position: absolute;
        top: 0;
        width: 100vw;

        video {
            width: 100%;
        }
    }
}