.product-detail-usps {
    background-color: #F6F6F8;
    padding: 18px 0 14px 0;
    border-radius: 8px;
    position: relative;
    order: -1;
    margin-bottom: 20px;

    @media only screen and (max-width: 767px) {
        margin-left: -16px;
        margin-right: -10px;
        border-radius: 0px;
    }

    @media only screen and (min-width: 768px) {
        margin-bottom: 20px;
    }

    ul {
        list-style-type: none;
        padding-left: 0;
        padding-right: 15px;
        font-family: $font-family__brand;

        @media only screen and (min-width: 1280px) {
            padding-right: 100px;
        }

        li {
            padding-left: 44px;
            position: relative;
            color: #222950;
            font-size: 16px;
            margin-bottom: 10px;

            @media only screen and (max-width: 767px) {
                padding-left: 35px;
            }


            &::before {
                content: url("data:image/svg+xml,%0A%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5.44444L4.2 9L9 1' stroke='%23E6520F' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                display: inline-block;
                font-size: 12px;
                left: 24px;
                top: 50%;
                transform: translateY(-50%);
                position: absolute;

                @media only screen and (max-width: 767px) {
                    left: 15px;
                }

            }

            a {
                color: #707070;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    img {
        position: absolute;
        right: 20px;
        bottom: 0;
        width: 186px;
        height: 124px;
        object-fit: contain;

        @media only screen and (max-width: 1280px) {
            display: none;
        }
    }
}
