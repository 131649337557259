.popup-widget {
    .action-close {
        &::before {
            color: $brand__secondary__color;
        }
    }
}

.modal-popup.visual {
    .modal-inner-wrap {
        align-items: center;
        background-color: transparent;
        box-shadow: none;
        width: fit-content;

        @media only screen and (max-width: 767px) {
            margin: 0;
            transform: translate(-50%, -50%) !important;
            top: 50%;
            left: 50%;
            width: 90%;
        }

        .modal-header {
            padding: 0;

            .action-close {
                padding: 1.2rem 0.5rem;

                &::before {
                    color: $brand__primary__color;
                    font-size: 30px;
                }
            }
        }

        .modal-content {
            padding: 0;
            width: fit-content;
        }

        .visual-popup {
            .modal-body-content {
                > div {
                    width: fit-content;
                }
            }
        }
    }
}