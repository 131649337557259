#takeover-left-banner-container,
#takeover-right-banner-container,
#takeover-top-banner-container {
    picture {
        line-height: 0;
        font-size: 0;
    }
}

#takeover-left-banner-container,
#takeover-right-banner-container {
    position: absolute;
    top: 220px;
    width: calc((100vw - (100vw - 100%) - 1368px) / 2); // Get available space for both sides of the screen outside the container
    max-height: 100dvh;
    overflow: hidden;

    @include max-screen($screen__xxl) {
        display: none;
    }

    @include min-screen($screen__xxxl) {
        max-height: none;
        overflow: auto;
    }

    figure {
        > picture {
            > img {
                object-fit: cover;
                width: 100%;

                @include min-screen($screen__xxxl) {
                    object-fit: contain;
                    height: 1000px !important;
                    max-height: 1000px;
                }
            }
        }
    }

    .pagebuilder-mobile-only {
        display: none;
    }
}

#takeover-left-banner-container {
    left: 0;
}

#takeover-right-banner-container {
    right: 0;
}

#takeover-top-banner-container {
    margin-bottom: $indent__base;
    max-height: 275px;
    overflow: hidden;

    @include max-screen($screen__m) {
        margin-left: -16px;
        margin-right: -16px;
    }

    @media only screen and (min-width: $screen__m) and (max-width: $screen__xl) {
        margin-left: -32px;
        margin-right: -32px;
    }

    figure {
        display: flex;

        > picture {
            > img {
                object-fit: cover;
                min-height: 100px;
                max-height: 275px;
            }
        }
    }

    .pagebuilder-mobile-only {
        display: none;
    }
}