.ambrands-slider-container {
    .ambrands-slider.amslider {
        max-width: none !important;
    }

    .amslider-header-container {
        @include block-title();
        text-align: left;
        font-size: 2.8rem;
        line-height: 1.1;
        font-weight: 700;

        @include min-screen($screen__l) {
            font-size: 4rem;
            margin-bottom: $indent__l;
        }
        strong {
            display: block;
            margin-bottom: 0;
            font-size: 1.4em;
        }
    }

    .amslider .swiper-button-next,
    .amslider .swiper-button-prev {
        background-color: $brand__secondary__color;
    }

    .amslider .swiper-pagination-bullet {
        background-color: $brand__secondary__color;
        width: 12px;
        height: 12px;
    }
}