body.with-saw-option {
    .addtocart-qty-increments {
        display: flex;
        gap: 15px;
        margin-top: 25px;

        .box-tocart {
            display: flex;
            justify-content: flex-end;
            margin-top: 0;
        }

        .product.pricing {
            color: $brand__secondary__color;
            font-weight: 500;
            max-width: 200px;
        }
    }

    .stock-status-social {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;

        @media only screen and (max-width: 400px) {
            flex-direction: row;
        }

        .product-addto-links {
            .add-to-link.towishlist {
                font-size: $font-size__base_custom;
                font-family: $font-family__brand;
                font-weight: 500;
                color: #73778C;
            }
        }
    }

    .price-addtocart {
        display: flex;
        gap: 20px;
        margin-top: 27px;

        .box-tocart {
            margin-top: 0;
            margin-left: auto;
            max-width: 450px;

            .action.tocart {
                margin-top: 0;
            }
        }
    }
}