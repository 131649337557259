// Brand overview page
.ambrands-brandlist-widget {
    .content {
        display: flex;
        flex-direction: column;

        .ambrands-letters-list {
            display: flex;
            flex-direction: column;

            .ambrands-letter {
                width: 100%;

                .ambrands-brand-item {
                    width: 100%;
                    max-width: calc((100% / 2) - 16px);

                    @include min-screen($screen__s) {
                        max-width: calc((100% / 3) - 16px);
                    }

                    @include min-screen($screen__m) {
                        max-width: calc((100% / 4) - 16px);
                    }

                    @include min-screen($screen__l) {
                        max-width: calc((100% / 5) - 16px);
                    }

                    @include min-screen($screen__xl) {
                        max-width: calc((100% / 7) - 16px);
                    }

                    .ambrands-inner {
                        box-shadow: none;
                        border-color: $border-color__base;
                        height: 150px;
                        color: $text__color;

                        &:hover {
                            border-color: $text__color;
                        }
                    }
                }
            }
        }
    }
}