#product-options-calculator {
    display: flex;
    justify-content: space-between;
    font-family: $font-family__brand;
    margin-top: 28px;
    margin-bottom: 30px;
    padding-bottom: 7px;
    padding-top: 28px;
    border-bottom: 1px solid #E3E4E8;
    border-top: 1px solid #E3E4E8;
    gap: 33px;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }

    .content {
        display: flex;
        flex-direction: column;
        width: 50%;

        @media only screen and (max-width: 768px) {
            width: 100%;
        }

        .summary {
            font-weight: 600;
            color: $brand__secondary__color;
            font-size: 17px;
        }

        .custom-content {
            color: #73778C;
            font-size: $font-size__base_custom
        }
    }

    .summary-list {
        list-style: none;
        padding-left: 0;
        width: 50%;

        @media only screen and (max-width: 768px) {
            width: 100%;
        }

        li {
            display: flex;
            justify-content: space-between;
            column-gap: 12px;
            color: #73778C;
            border-bottom: 1px solid #E3E4E8;
            padding-bottom: 14px;
            padding-top: 4px;

            &:last-child {
                border-bottom: none;
            }

            span {
                font-weight: 700;
                color: $brand__secondary__color;
            }
        }
    }
}