.filter-wrap {
    .block.filter {
        @include brand-block(
            $enabled: true
        );

        width: 80%;
        position: fixed;
        z-index: 999;
        top: 0;
        bottom: 0;
        left: -80%;
        transform: translate3d(0, 0, 0);
        margin-bottom: 0;
        overflow-x: hidden;
        transition: transform 0.2s ease;

        @include min-screen($screen__m) {
            border: none;
            padding: 0;
            margin-bottom: $indent__xl;
            position: static;
            width: auto;
            min-width: unset;
            transform: none;
        }

        .filter-title {
            margin-bottom: $indent__base;
            display: flex;

            strong {
                font-size: 2.2rem;
                display: block;
                flex-grow: 1;
            }

            .filter-close {
                font-size: 1.5em;
                text-align: center;
                vertical-align: middle;
                align-self: center;
                cursor: pointer;

                @include min-screen($screen__m) {
                    display: none;
                }
            }
        }

        .filter-content {
            width: 100%;

            .block-subtitle {
                display: none;
            }
        }
    }

    .filter-background {
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 998;
        background: $color-black;
        opacity: 0;
        transition: opacity 3s ease;
    }

    &.hidden {
        .block-filter {
            transform: translate3d(0, 0, 0);
        }

        .filter-background {
            opacity: 0;
        }
    }

    &.visible {
        display: block;

        .block.filter {
            transform: translate3d(100%, 0, 0);
        }

        .filter-background {
            transition: opacity 3s ease;
            display: block;
            opacity: 0.4;
        }
    }
}

.filter-current {
    .block-subtitle {
        display: inline-block;
        margin-bottom: $indent__xs;
        font-size: 1.6rem;
    }

    .filter-group-title {
        margin-top: 5px;
        margin-bottom: 0;
    }

    .filter-label {
        font-weight: $font-weight__semibold;
        font-size: 1.5rem;
    }

    .item.am-shopby-item {
        font-size: $font-size__s;
    }

    .action.remove {
        color: $brand__secondary__color;
        text-decoration: none;
        vertical-align: middle;
        position: relative;
        top: -0.2em;

        i {
            vertical-align: middle;
        }
    }
}

.filter-actions {
    margin-top: $indent__xs;
    margin-bottom: $indent__l;

    .action.filter-clear {
        font-size: $font-size__s;
        font-family: $font-family__brand;
        text-transform: uppercase;
        color: $link__color;

        &:hover {
            color: $link__hover__color;
        }
    }
}

.layered-top-nav {
    margin-bottom: 2rem;

    @include min-screen($screen__m) {
        display: none;
    }

    .filter-current {
        margin-bottom: 1rem;

        .block-subtitle {
            font-weight: $font-weight__semibold;
            font-size: $font-size__base;
            margin-bottom: 0.8rem;
        }

        .item.am-shopby-item {
            padding: 0.4rem 0.8rem;
            border-radius: $brand__border-radius;
            border: 1px solid $brand__secondary__color;
            display: inline-flex;
            align-items: center;
            cursor: pointer;
            margin: 0 0.5rem 0.5rem 0;
            font-size: $font-size__s;

            &:hover {
                background: $brand__secondary__color;
                color: $color-white;

                .action.remove {
                    color: $color-white;
                }
            }

            .action.remove {
                margin-right: 0.3em;
                top: -0.1em;
            }
        }

        .filter-group-title {
            display: block;
            margin-top: 0;
            margin-bottom: $indent__xs;
        }
    }

    .layered-top-nav-actions {
        text-align: center;

        .toggle-filter {
            display: block;
            width: 100%;
            color: $color-black;
        }
    }
}

.filter-options-content {
    .filter-count-label {
        @extend .abs-visually-hidden;
    }
    &:has(.amshopby-search-box input:not(:placeholder-shown)) {
        .am-show-more {
            opacity: 0;
            visibility: hidden;
        }
    }

    .amshopby-fromto-wrap .range {
        flex-wrap: wrap;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: $indent__ms;
        text-align: center;

        .amshopby-input-wrapper {
            max-width: 25%;
        }

        .am-filter-price {
            min-width: 75px;
            color: $text__color;
        }
        
        .amshopby-currency {
            display: none;
        }

        .am-filter-go {
            margin: $indent__s;
            width: unset;
            background: $brand__secondary__color;
            border-radius: $brand__border-radius;

            &:hover {
                background: $brand__secondary__darker;
            }
        }
    }
}

.filter-options {
    margin: 0;

    .filter-options-title {
        font-size: 1.5rem;
        word-break: break-all;
        color: $primary__color;
        font-weight: $font-weight__semibold;
        margin: 0 0 0.7rem;
        padding-top: 1rem;
        display: flex;
        cursor: pointer;

        span {
            flex-grow: 1;
        }

        .filter-options-expand {
            cursor: pointer;
        }
    }

    .filter-options-content {
        margin: 0 0 $indent__m;
        line-height: 1.5;
        .am-swatch-link {
            display: inherit;
        }
        .am-swatch-wrapper {
            display: block;
            margin-bottom: 3px !important;
        }
        .items.-am-singleselect .amshopby-link-selected .count,
        .swatch-option.selected + .am-shopby-swatch-label .count {
            color: $text__color;
        }

        .items .item, .am-swatch-wrapper {
            margin-bottom: 3px;
            text-decoration: none;

            a,
            .label,
            .count {
                text-decoration: none;
                color: $text__color;
                font-size: $font-size__s;
                display: flex;
                align-items: center;

                &:hover {
                    color: $text__color;
                }
            }

            .count {
                font-size: $font-size__xs;
            }

            input {
                margin-right: 0.3em;
            }

            .swatch-option {
                display: flex;
            }
        }

        .am-show-more {
            display: none;
            padding: 0;
            width: 100%;
            color: $brand__secondary__color;
            font-family: inherit;
            text-align: left;
            text-transform: none;
            cursor: pointer;
        
            &.-active {
                display: block;
            }

            &.-disabled {
                display: none;
            }

            &:hover {
                color: $text__color;
            }

            &:after {
                content: fa-content($fa-var-chevron-down);
                display: inline-block;
                margin-left: 5px;
                font-size: 12px;
                font-weight: 900;
                font-family: "Font Awesome 5 Pro";
                transition: transform 0.3s ease-in-out;
            }

            &[data-is-hide="true"]:after {
                transform: rotate(0);
            }

            &[data-is-hide="false"]:after {
                transform: rotate(-180deg);
            }
        }
    }

    .count {
        font-size: 0.8em;
        color: lighten($text__color, 0.8);
        margin-top: 0.3em;
        margin-left: 0.3em;

        &:before {
            content: '(';
        }

        &:after {
            content: ')';
        }
    }
}

.items {
    @extend .abs-reset-list;
}

.filtered {
    .items {
        margin: 15px 0;
    }

    .item {
        margin-bottom: 6px;
        padding-left: 22px;
        position: relative;

        .label {
            font-weight: $font-weight__bold;
        }

        .action.remove {
            @extend .abs-remove-button-for-blocks;
            left: -6px;
            position: absolute;
            top: 0;
        }
    }

    & + .actions {
        margin-bottom: 35px;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .page-with-filter {
        .columns {
            .sidebar-main {
                @include lib-vendor-prefix-order(2);
            }
        }
    }
}
