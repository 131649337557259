.fotorama__arr__arr {
    .fotorama__arr & {
        width: 24px;
        height: 29px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
    }
}
