.fotorama__dot {
    top: 0;
    left: 6px;
    width: 11px;
    height: 11px;
    border: 1px solid #E0E2EB;
}

.fotorama__nav--dots {
    margin-right: 6px;
}
