#backinstock-notice-wrapper {
    background-color: #f6f6f8;
    padding: 1.8em;
    border-radius: 8px;
    margin: 2em 0;
    font-family: $font-family__brand;
    display: none;

    &:has(.is-active) {
        display: block;
    }

    label.error {
        padding: 0.5em 0;
        color: $error__color;
        display: inline-block;
    }

    #backinstock-notice {
        background-color: white;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border: 1px solid #E0E2EB;
        padding: 3.5em 2.5em;

        @media only screen and (max-width: 400px) {
            padding: 2.2em 1.5em;
        }
    }

    #backinstock-notice h3 {
        color: #333;
        font-size: $font-size__xl;
        margin-bottom: 0.3em;
        text-transform: inherit;
        font-weight: 400;
    }

    #backinstock-notice p {
        color: #707589;
        font-size: $font-size__base_custom;
        margin-bottom:0;
    }

    #backinstock-notice input[type="email"] {
        width: 100%;
        padding: 0.9em 1.8em;
        margin-top: 2em;
        margin-bottom: 0.8em;
        border: 1px solid #E0E2EB;
        border-radius: 4px;
        box-sizing: border-box;
        font-size: 1em;
        &:disabled {
            color: #707589;
            background-color: #E0E2EB;
        }
        &::placeholder {
            color: #707589;
        }
    }

    #backinstock-notice button {
        position:relative;
        width: 100%;
        padding: 0.9em 1.8em;
        border-radius: 4px;
        justify-content: center;
        align-items: center;
        display: flex;
        color: $color-black;
        &.loading {
            cursor: not-allowed;
        }

        &.loading::after {
            content: "";
            width: 16px;
            height: 16px;
            border: 2px solid #fff;
            border-top-color: transparent;
            border-radius: 50%;
            animation: rotate 1s ease infinite;
            transform: translate(-50%, -50%);
        }

        @keyframes rotate {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }
}
.action.primary.backinstock {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 4px;
    border-bottom: 1px solid #DFDFDF;
    background: #F5F5F5;
    color: #6F6F71;
    &:hover, &:visited {
        color: #6F6F71;
        background: #F5F5F5;
    }
}
.products-list:nth-child(even) .item:nth-child(odd) {
    .action.primary.backinstock {
        background-color: #e9e9e9;
        border-color: #cecdcd;
        &:hover, &:visited {
            background-color: #e9e9e9;
        }
    }
}
