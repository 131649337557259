.message {
    padding-left: 20px;

    i[class*="fa-"] {
        margin-right: 0.5em;
    }
}

.message.info {
    @include lib-message-icon-inner(info);

    line-height: 1.4em;
    padding-left: 20px;
    margin-top: $indent__s;
}

.message.error {
    @include lib-message-icon-inner(error);

    line-height: 1.4em;
    padding-left: 20px;
    margin-top: $indent__s;
}

.message.warning {
    @include lib-message-icon-inner(warning);

    line-height: 1.4em;
    padding-left: 20px;
    margin-top: $indent__s;
}

.message.notice {
    @include lib-message-icon-inner(notice);

    line-height: 1.4em;
    padding-left: 20px;
    margin-top: $indent__s;
}

.message.success {
    @include lib-message-icon-inner(success);

    line-height: 1.4em;
    padding-left: 20px;
    margin-top: $indent__s;
}
