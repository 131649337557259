.gallery-placeholder {
    @include min-screen($screen__l) {
        max-width: 80%;
        margin: 0 auto;
    }

    .fotorama__thumb-border {
        display: none;
    }
    &:not(._block-content-loading) {
        // Hide placeholder when gallery is loaded
        .gallery-placeholder__image {
            display: none;
        }
    }
}

.fotorama__thumb-border {
    display: none;
}

.fotorama__nav {
    overflow: visible !important;
}

.fotorama__nav--thumbs {
    display: flex !important;
    justify-content: center;
    margin-top: 20px !important;

    @include max-screen($screen__l) {
        justify-content: flex-start;
    }
}

.fotorama__stage {
    max-height: 400px !important;

    @media only screen and (max-width: 1280px) {
        max-height: 300px !important;
    }

    &__frame {
        width: calc(100% - 32px) !important;
        margin-left: 16px;
        margin-right: 16px;
    }
}

.fotorama__nav--thumbs .fotorama__nav__frame {
    border-radius: 6px;
    border: 1px solid #E3E4E8;
    margin-right: 15px;
    height: 69px !important;
    width: 84px !important;
    padding: 5px !important;

    &.fotorama__active {
        box-shadow: 0 0 0 5px #F1F1F4;
    }
}
